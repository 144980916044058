<template>
  <div >
    <HeaderView :page_index="-1" />
    <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left; margin: 30px 30px 30px 30px;" />
    <div v-else class="home">
      <el-container>
      <el-container>
        <el-aside width="200px">

        </el-aside>
        <el-container>
          <el-skeleton v-if="loading" :rows="5" animated  style="text-align: left;" />
          <el-main  v-else style="text-align: left;">
            <div class="head_view">
              <div class="title">《{{info.name}}》</div>
              <div class="desc"> {{info.desc}}</div>
            </div>
            <router-link v-for="(chapter, index) in info.list" :key="index" class="one_chapter" target="_blank" :to="{ name: 'Book_chapter', params: { id: chapter.id }}">
             <span>{{ index+1 }} </span> {{ chapter.title }}
            </router-link>
          </el-main>
        </el-container>
      </el-container>
    </el-container>
  </div>
</div>
</template>

<script>
import store from '@/store'
import Utils from '@/utils/utils'
import axios from 'axios'
import HeaderView from '@/components/other/HeaderView'

export default {
  name: 'Book_general',
  mounted () {
    this.get_index()
  },
  components: {
    HeaderView
  },
  data () {
    return {
      loading: true,
      loading2: false,
      loading3: false,
      is_long: false,
      niming: store.state.niming,
      base_img: store.state.base_img,
      type_list: [],
      pre_list: [],
      // in_wheres: [['不介意', null], ['结巴', 'j'], ['pku', 'p'], ['上版本', '1'], ['都介意', 'all']],
      see_wants: [['全部', 'all'], ['type', 'type'], ['my', 'my'], ['net', 'net'], ['都不', 'none']],
      current_type_index: 0,
      current_group_index: 0,
      current_page: 1,
      zici_paixu: 'index',
      group_tag: 'my',
      person_selects: [],
      see_want: 'net',
      content2: ''
    }
  },
  methods: {
    get_index () {
      axios.post(Utils.check_is_niming() ? '/book_general_unlogin/' : '/book_general/', { id: this.$route.params.id })
        .then(res => {
          if (res.data.code !== 200) {
            Utils.alert(res.data.msg)
            return
          }
          this.info = res.data.data.info
          this.loading = false
          document.title = this.info.name
        })
    }
  }
}
</script>
<style scoped>
.head_view{
  margin: 30px 0px;
}
.head_view .title{
  color: #50b7c1;
  font-size: 32px;
  font-weight: bold;
}
.head_view .desc{
  margin: 10px 100px 10px 0px;
  font-size: 16px;
}
.one_chapter{
  display: block;
  cursor:pointer;
  padding: 6px 0px;

}
</style>
